import { setLang } from '@/utils/lang'
import { setRtl } from '@/utils/survey'
import { isEqual } from 'lodash'

export const PollMixin = {
  data() {
    return {
      error: null,
      changes: {},
      ready: false
    }
  },
  computed: {
    targetOrigin() {
      return this.$route.query?.targetOrigin
    },
    code() {
      return this.$route.params?.code
    },
    loading() {
      return this.$store.getters.loading(this.code)
    },
    poll() {
      return this.$store.getters.poll(this.code)
    },
    response() {
      return this.$store.getters.response(this.code)
    },
    question() {
      return this.poll?.question
    },
    lang() {
      return this.$i18n.locale;
    },
    isFinished() {
      return !!this.response?.submitted_at
    },
    totalAnswer() {
      return this.$store.getters.pollResult(this.code)?.total || 0
    }
  },
  watch: {
    question (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.ready = true
			}
    },
    isFinished (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.ready = true
			}
    }
  },
  methods: {
    onChange(data) {
      this.changes = { ...this.changes, ...data }
    },
    async postMessageForm() {
      if(!this.ready) return;
      const element = document.querySelector('#poll-container');
      if(element.offsetHeight === 0 || !this.code || !this.question) return;

      window.parent.postMessage(
        {
          height: element?.offsetHeight + 50 || '100%',
          code: this.code,
        },
        this.targetOrigin
      );
    },
    async sendMessage() {
      const resizeObserver = new ResizeObserver(async () => {
        await this.postMessageForm()
      });
      resizeObserver.observe(document.getElementById('poll-container'));
    },
    setLang() {
      const { lang } = this.$route.query;
      this.$i18n.locale = lang;
      setLang(lang);
      if (['ar', 'fa', 'ur'].includes(lang)) setRtl(true);
    }
  },
  async mounted() {
    await this.sendMessage()
  }
}
